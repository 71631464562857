<template>
  <div id="mall" ref="box">
    <!-- 顶部组件 -->
    <top :name="text" />
    <div class="lunbo_div"></div>
    <div class="search_div">
      <van-search
        @click="is"
        style="position: relative; z-index: 9999; flex: 1"
        shape="round"
        background="none"
        v-model="search_value"
        placeholder="搜索商品名称或类型"
      />
      <div
        @click="searct_fun()"
        class="sou"
        :style="is_sear == true ? 'display: block;' : 'display: none;'"
      >
        搜索
      </div>
    </div>

    <van-swipe
      class="my-swipe"
      :heigh="183"
      :autoplay="2000"
      indicator-color="white"
    >
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <img :src="item" alt="" class="lunbo" />
      </van-swipe-item>
    </van-swipe>
    <div
      style="
        padding: 0.2rem;
        width: 96%;
        margin: 0px auto;
        border-radius: 0.2rem;
        background: white;
        position: relative;
        top: 0.1rem;
      "
    >
      <van-grid :border="flase" :column-num="4">
        <van-grid-item
          style="font-size: inherit"
          v-for="(item, index) in imgtext"
          :key="index"
          :icon="item.img"
          :text="item.text"
          @click="bage(item.inner)"
        />
      </van-grid>
    </div>
    <!-- 品质严选 -->
    <div class="pinzhi">
      <div v-for="(item, index) in item_img" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>
    <!-- 品质严选 End -->
    <!-- 商城拼团购 -->
    <div class="jinxuan_mall">
      <!-- <a href="/" style="font-size: 0.3rem; color: black; font-weight: bold"
        >商城拼团购</a
      > -->
      <div></div>
      <div class="iob">
        <p class="text_p">商城拼团购</p>
      </div>
      <p
        @click="gengduo('all')"
        style="
          color: 00adf7;
          font-size: 0.2rem;
          color: black;
          font-weight: gray;
        "
      >
        更多 >>
      </p>
      <!-- <a
        href="/"

        >更多>></a
      > -->
    </div>
    <div class="floor-container seckill-slider box">
      <!-- 滑动div -->
      <div class="huadong">
        <div
          class="body_div"
          style="width: 3rem"
          v-for="(item, index) in tuangou"
          :key="index"
          @click="collagedot(item.id)"
        >
          <img :src="item.group_image" alt="" />
          <p
            style="
              font-size: 0.28rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            "
          >
            {{ item.group_name }}
          </p>
          <div
            style="
              font-size: 0.28rem;
              display: flex;
              margin-top: 0.1rem;
              align-items: center;
              justify-content: space-between;
            "
          >
            <p style="color: red">￥{{ item.display_price }}</p>
            <p style="color: gray; font-size: 0.24rem">
              剩余{{ item.rmi_inventory }}人
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="tc skill-item">
        <div>
          <img src="" />
        </div>
        <p class="price-mid"><span style="color: red">10</span>人</p>
      </div> -->
    </div>
    <!-- 商城拼团购 End-->
    <!-- 限时特惠 -->
    <!-- <img style="margin: .16rem 0 0rem 0;" src="https://mall.yaolvxing.com/image/xianshi.png" alt=""> -->
    <!-- 限时特惠 End-->
    <!-- 商城推荐 -->
    <div class="jinxuan_mall">
      <a
        href="/"
        style="
          margin-left: 0.2rem;
          font-size: 0.3rem;
          color: black;
          font-weight: bold;
        "
        >商城推荐</a
      >
      <!-- <a href="/" style="font-size: 0.2rem; color: black; font-weight: gray"
        >更多>></a
      > -->
    </div>
    <div class="ul_div" style="display: inline-block; width: 100%">
      <van-list
        :immediate-check="ischeck"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul class="commend-list">
          <li
            @click="todetails(item.id)"
            v-for="(item, index) in data"
            :key="index"
          >
            <div class="commend-img">
              <img class="" :src="item.image" alt="" />
            </div>
            <h3
              style="
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ item.name }}
            </h3>
            <div class="commend-sale" style="clear: both">
              <span style="font-size: 0.28rem; color: red; float: left"
                >现金：{{ item.price }}元 <br />
                +{{ item.integral }}积分</span
              >

              <span style="float: right">销量{{ item.sales }}</span>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
    <!-- 搜索组件 -->
    <sreach v-on:guanbiseacht="guanbi" :data="remen" v-if="is_sear == true" />
    <!-- 底部组件 -->
    <bottom />
  </div>
</template>

<script>
// 定向微信小程序JSSDK
import top from "../components/filltop.vue";
import bottom from "../components/bottom.vue";
import sreach from "../components/sreach.vue";
import Vue from "vue";
export default {
  name: "app",
  components: {
    bottom,
    top,
    sreach,
  },

  data() {
    return {
      ischeck: false,
      loading: false,
      finished: false,
      text: "要旅行商城",
      // 是否显示搜索
      is_sear: false,
      // 商城推荐
      item_img: [
        "https://mall.yaolvxing.com/image/pintai.png",
        "https://mall.yaolvxing.com/image/pinzhi.png",
        "https://mall.yaolvxing.com/image/zuixing.png",
      ],
      images: [
        "https://mall.yaolvxing.com/image/banner1.png",
        "https://mallimages.csair.com/album/2021/12/6/19da391e-a516-4cec-92d2-ddb43b0f33d2.jpg",
        "https://mallimages.csair.com/album/2022/3/17/e7945fb3-bbb9-4435-af3d-b9594a3a3126.png",
        "https://mallimages.csair.com/album/2021/11/19/dd5c7b1c-5917-49ad-977b-bba7cd60c5e2.png",
      ],
      search_value: "",
      flase: false,
      //   宫格布局  文字图片
      imgtext: [
        {
          img: "https://mall.yaolvxing.com/image/icon_meishi.png",
          text: "粮油食品",
          inner: "粮油食品",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_jiaju.png",
          text: "家具生活",
          inner: "家具",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_fushi.png",
          text: "服饰箱包",
          inner: "服饰",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_yanchu.png",
          text: "电影/演出",
          inner: "未开通",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_gouwuche.png",
          text: "购物区链",
          inner: "未开通",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_yinping.png",
          text: "零食饮料",
          inner: "零食",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_piao.png",
          text: "票券",
          inner: "未开通",
        },
        {
          img: "https://mall.yaolvxing.com/image/icon_lvyou.png",
          text: "旅游出行",
          inner: "旅游",
        },
      ],
      page: 1,
      data: {},
      remen: {},
      count: 0,
      tuangou: [],
    };
  },
  methods: {
    // 接收小程序传值
    getwx(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      // var url =decodeURI(decodeURI(window.location.search)); //获取url中"?"符后的字串，使用了两次decodeRUI解码
      var url = window.location.search;
      var r = url.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    onLoad() {
      var that = this;
      this.loading = true;
      console.log("触发");
      this.page = this.page + 1;
      setTimeout(() => {
        this.$https(
          "goods/list",
          "page=" + this.page + "" + "&is_id=" + "false",
          "get",
          "false"
        ).then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.data);
            res.data.data.forEach((item, index) => {
              this.data.push(item);
            });
            this.loading = false;
          }
        });
      }, 1000);
    },
    // 详情请求
    todetails(id) {
      this.$https("goods/details", "goods_id=" + id + "+", "post")
        .then((res) => {
          if (res.data.code == 200) {
            this.$router.push({ name: "details", params: { success: res } });
          } else {
            // 提示错误
            this.$toast.fail(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //
    },
    // 查询全部团购商品
    gengduo(id) {
      this.$router.push({
        path: "/collage/collagenumber",
        query: {
          id: id,
        },
      });
    },
    // 搜索
    searct_fun() {
      this.$router.push({
        name: "productlist",
        params: { success: this.search_value, isfalse: "false" },
      });
    },
    // 关闭监听
    guanbi(e) {
      this.is_sear = e;
    },
    is() {
      this.is_sear = true;
      this.$https("class/hot", "", "get", "false")
        .then((res) => {
          if (res.data.code == 200) {
            this.remen = res.data.data.data;
          } else {
            // 提示错误
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 八个类目点击时搜索
    bage(innertext) {
      if (innertext != "未开通") {
        this.search_value = innertext;
        this.searct_fun();
      } else {
        this.$toast.fail("模块未开通，请期待");
      }
    },
    collage() {
      this.$https(
        "collage/list",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&page=" +
          1 +
          "&static=" +
          "all",
        "post"
      ).then((res) => {
        this.tuangou = res.data.data;
      });
    },
    collagedot(id) {
      this.$https(
        "collage/deleta",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&collage=" +
          id,
        "post"
      ).then((res) => {
        if (!(res.data.msg == "正常响应，数据为空")) {
          this.$router.push({
            name: "collagedot",
            params: { success: res.data.data },
          });
        } else {
          this.$toast.fail("商品已满人或已下架");
          this.collage()
        }
      });
    },
  },
  // 请求商城数据
  mounted() {
    this.$https("class/hot", "", "get", "false")
      .then((res) => {
        if (res.data.code == 200) {
          this.remen = res.data.data.data;
        } else {
          // 提示错误
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // 接收小程序传值 判断是否有该用户  -10(没有该用户，跳转进入设置密码页面) 000000(有该用户，正常使用商城)
    // 将用户手机号码以及头像存入本地缓存 方便读取
    if (JSON.parse(this.getwx("vuedata")).id == -10) {
      sessionStorage.setItem(
        "user_phone",
        JSON.parse(this.getwx("vuedata")).phone
      );
      sessionStorage.setItem(
        "user_image",
        JSON.parse(this.getwx("vuedata")).user_image
      );
      sessionStorage.setItem(
        "wx_openid",
        JSON.parse(this.getwx("vuedata")).wxid
      );
      // alert("请先登录，再使用要旅行商城")
      this.$router.push({
        name: "user",
        params: { success: this.getwx("vuedata") },
      });
    } else if (JSON.parse(this.getwx("vuedata")).id == "000000") {
      sessionStorage.setItem(
        "user_phone",
        JSON.parse(this.getwx("vuedata")).phone
      );
      sessionStorage.setItem(
        "user_image",
        JSON.parse(this.getwx("vuedata")).user_image
      );
      sessionStorage.setItem(
        "user_id",
        JSON.parse(this.getwx("vuedata")).userid
      );
      sessionStorage.setItem(
        "wx_openid",
        JSON.parse(this.getwx("vuedata")).wxid
      );
    }
    this.collage()
  },
  created() {
    // if(){}
    setTimeout(() => {
      this.$refs.box.scrolltop = 10;
    }, 1000);
    this.$https(
      "goods/list",
      "page=" + this.page + "" + "&is_id=" + "false",
      "get",
      "false"
    ).then((res) => {
      if (res.data.code == 200) {
        this.data = res.data.data;
        // console.log(this.data);
      }
    });
    // console.log( JSON.parse(this.getwx("vuedata")));
  },
};
</script>

<style scoped src="../viewscss/viewscss.css">
</style> 