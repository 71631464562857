<template>
  <div>
    <div class="header-nav-float am-navbar am-navbar-dark">
      <div @click="goto()" style="margin-left: 0.2rem">
        <van-icon name="arrow-left" />
      </div>
      <div style="text-align: center">搜索</div>
      <div style="margin-right: 0.2rem"></div>
    </div>
    <div class="zhezhao"></div>
    <div class="searchBox">
      <p
        v-if="istrue == true"
        style="padding: 0.4rem 0px 0rem 0; font-size: 0.28rem"
      >
        热门搜索
      </p>
      <div class="remen" v-if="istrue == true">
        <span
          v-for="(item, index) in success"
          :key="index"
          @click="list(item.word)"
          >{{ item.word }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"], //or props:{name:{type:String,default:''}}
  data() {
    return {
      text: "搜索",
      success: {},
      page: 1,
      istrue: true,
    };
  },
  components: {},
  created() {
    this.$route.path === "/collage/productlist"
      ? (this.istrue = false)
      : (this.istrue = true);
  },
  mounted() {
    this.success = this.$emit("data").data;
  },
  methods: {
    goto() {
      this.$emit("guanbiseacht", false);
    },
    list(item) {
      if (this.$route.path === "/collage/productlist") {
        // 重复路由  关闭并传值
        this.$emit("guanbiseacht", false);
        this.$emit("resdata", item);
      } else {
        this.$router.push({
          name: "productlist",
          params: { success: item, isfalse: "false" },
        });
      }
      // this.$https(
      //   "goods/list",
      //   "page=" + this.page + "&value=" + item +"&is_id="+'false',
      //   "get"
      // ).then((res) => {
      //   if (res.data.code == 200) {
      //     if (this.$route.path === '/collage/productlist') {
      //         // 重复路由  关闭传值
      //         console.log("重复路由")
      //         this.$emit("guanbiseacht",false);
      //         this.$emit("resdata",res.data);
      //     }else{
      //     this.$router.push({
      //       name: "productlist",
      //       params: { success: res.data },
      //     });
      //     }
      //     // console.log(res.data);
      //   } else {
      //     console.log("请求失败  请重新检查接口");
      //   }
      // });
    },
  },
};
</script>

<style scoped>
.searchBox {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding: 2rem 0.2rem 2rem 0.2rem;
  overflow: scroll;
}
.remen {
  width: 100%;
  height: auto;
}
.remen span {
  font-size: 0.28rem;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  background: #a8a8a8;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
  color: white;
}
.zhezhao {
  width: 100%;
  height: 0.9rem;
}
.header-nav-float {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999;
}
.am-navbar,
.am-navbar-left,
.am-navbar-right,
.am-navbar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.am-navbar {
  -ms-flex-align: center;
  height: 1rem;
  justify-content: space-between;
  background-color: #008acb;
  color: #fff;
  font-size: 0.34rem;
}
.am-navbar div {
  flex: 1;
}
</style>