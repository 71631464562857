<template>
<div>
    <van-tabbar v-model="active" @change="onChange" route>
      <van-tabbar-item replace to="/" icon="shop-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/classification" icon="apps-o">分类</van-tabbar-item>
      <van-tabbar-item replace to="/cart" icon="shopping-cart-o">购物车</van-tabbar-item>
      <van-tabbar-item replace to="/personalcenter" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
    <div class="a"></div>
</div>

</template>
<script>

export default {
    data () {
        return {
            active :0
        }
    },
  methods: {
    onChange(index) {
      // console.log(index)
      // this.active == index
      // console.log(this.active)
    },
  },
};
</script>
<style scoped>
.a{
    width: 100%;
    height: 13.333333vw;
}
</style>